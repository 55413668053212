import axios from 'axios'

// axios.defaults.withCredentials = true

const service = axios.create({
  baseURL: process.env.BASE_URL, // url = base url + request url
  timeout: 30000,
  withCredentials: true // send cookies when cross-domain requests
})

// Request interceptors
service.interceptors.request.use(
  (config) => {
    // Add X-Access-Token header to every request, you can add other custom headers here
    // if (UserModule.token) {
    //   config.headers['X-Access-Token'] = UserModule.token
    // }
    // config.headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8'
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

// Response interceptors
service.interceptors.response.use(
  (response) => {
    // Some example codes here:
    // code == 0: success
    // code == -1002: NOT PERMISSION
    // console.log('fetch have respone, ', response.request, response.data)
    const res = response.data
    if (res.core_result_code !== 0) {
      // Message({
      //   message: res.message || 'Error',
      //   type: 'error',
      //   duration: 5 * 1000
      // })
      // if (res.core_result_code === -1002) {
      //   MessageBox.confirm(
      //     'NOT PERMISSION',
      //     'NOT PERMISSION',
      //     {
      //       confirmButtonText: 'Ok',
      //       cancelButtonText: 'Cancel',
      //       type: 'warning'
      //     }
      //   ).then(() => {
      //     UserModule.ResetToken()
      //     location.reload() // To prevent bugs from vue-router
      //   })
      // }
      // return Promise.reject(new Error(res.message || 'Error'))
    }
    return response.data
  },
  (error) => {
    // Message({
    //   message: error.message,
    //   type: 'error',
    //   duration: 5 * 1000
    // })
    return Promise.reject(error)
  }
)

export default service
